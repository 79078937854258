import React, { useEffect, useState } from 'react';
import axios from 'axios';

const FileList = (props) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/files');
        setFiles(response.data);
      } catch (err) {
        setError('Error al cargar los archivos');
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, [props.enviar]);

  if (loading) {
    return <p>Cargando archivos...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <h2>Lista de Archivos</h2>
      <ul>
        {files.map((file) => (
          <li key={file.name}>
            <a href={`https://wwwa.isepsantafe.edu.ar/resoluciones/${file.name}`} target="_blank" rel="noopener noreferrer">
              {file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
