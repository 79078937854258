import React, { useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import './loguin.css'
import removeCokies from './removeCokies.js'
import seteaCokie from './seteaCookieFirstStep.js';
import { Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import compare from './compare.js'
import FooterCorp from '../modulos/footerCorp.jsx';
import obtieneDatos from './obtieneDatosCookiesUsuario.js'

var usuarioLogueado={}
const cookies=new Cookies();
const UR_usuarios=process.env.REACT_APP_BASEURL+"usuariosLoguin/"

function Loguin() {
  const [isVisible, setIsVisible] = useState(false);
const [cargando, setCargando] = useState(false);
 const navitate = useNavigate()
 removeCokies();
 
 const trataLoguear=()=>{
  try {
    usuarioLogueado= obtieneDatos()
  } catch (error) {
    
  }
  
 }
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});


const loguin=(cosa)=>{
  try {
   
        cookies.set('usuario',cosa.usuario, {path:"/"})
        cookies.set('pass',cosa.pass, {path:"/"})
        setCargando(true)
        getUsuarioById(cosa.usuario,cosa.pass)
      } catch (error) {console.error(error)}
      }

  const getUsuarioById=async (dato,dato2)=>{ 
    
      try {
        const primera= "  "
        setCargando(true)
     
        const estaAbierto= await axios.post(UR_usuarios+dato)
      
    
    if(estaAbierto.data){
    
      const check=await compare(dato2,estaAbierto.data.pass)

      if(check){
if(estaAbierto.data.estado==1){
  setCargando(false)

  seteaCokie(estaAbierto.data)
  if(estaAbierto.data.blanqueoContrasena==1){

    navitate('./cambioContrasena')
  }else{
    navitate('./usuarioLogueado')
  }
 
}else{
  alert("USUARIO NO HABILITADO!")
  setCargando(false)
}
       
       
       
      }else{

        alert("CREDENCIALES INVALIDAS")
        setCargando(false)
            }
     
    }
    else{
      alert("USUARIO NO ENCONTRADO")
      setCargando(false)
    }
     
      
      }catch(error){
        if(error.code=="ERR_NETWORK"){
        alert("No conecto al SERVER, espera para las consultas!")
        


      }
         setCargando(false)}}
         trataLoguear()

         if(usuarioLogueado.id!=null){
           alert("estan logueados")
         }


         useEffect(() => {
        
          const timer = setTimeout(() => {
              setIsVisible(true);
          }, 50); // 1500 milisegundos = 1.5 segundos
  
          // Limpiar el temporizador si el componente se desmonta
          return () => clearTimeout(timer);
      }, []);


   return (
    <div> 

      <div className='form__loguin_ingreso'>
        
      <div className={`imagen_loguin box ${isVisible ? 'show': ''}`}><h3 style={{"textAlign":"center","margin":"50px","color":"white"}}>
      Sistema Interno de Gestión y Expedientes Digitales I.Se.P
      </h3>
      
      </div>
   
      
    <form className="form_use" onSubmit={handleSubmit(loguin)}>
    

    
        <div className="form__container">
        <div className='escudo_loguin'></div>
        <div>
      {cargando && (<Spinner color='priority'/>)}
      </div>
    <h4 className="form__title_Important">Instituto de Seguridad Pública</h4>

          <div className="form__group">
          {errors.usuario?.type === 'required' && (
            <span className="aviso-campos-loguin">*</span>
          )}
          

          <input
            type="text"
            placeholder=" "
            className="form__input"
            id="usuario"
            
            {...register('usuario', {
              required: true,
              minLength: 6,
              maxLength:8
            })}
          />
            <label htmlFor ="dni" className="form__label">
              Usuario
            </label>
            <span className="form__line"></span>
        </div>
          <div className="form__group">
            {errors.pass?.type === 'required' && (
              <span className="aviso-campos-loguin">*</span>
            )}
           
            
            <input
              className="form__input"
              id="pass"
              type="password"
              placeholder=" "
              autoComplete='true'
              {...register('pass', {
                required: true,
                
              })}
            /> 
          <label htmlFor ="pass" className="form__label">
          Contraseña
          </label>
          <span className="form__line"></span>
          </div>

          <input type="submit" className="form__submit" value="Ingresar" />

          <h6 style={{textAlign:"center",margin:"-20px",fontSize:"10px"}}><strong>S.I.G.E.D.I</strong> V1.02.03b</h6>
          <FooterCorp/>
          
        </div>
      </form>
         </div>
    </div>
  );
}
export default Loguin;
