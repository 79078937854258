import {useState, useEffect} from 'react';
import { set, useForm } from 'react-hook-form';
import {useNavigate,useParams} from 'react-router-dom';
import Cookies from 'universal-cookie';
import obtieneDatos from './obtieneDatosCookiesUsuario'
import axios from 'axios';
import FooterCorp from '../modulos/footerCorp';
import devuelveDependenciaConstultada from './seteaDependencias';
import seteaCokie from '../tools/seteaCokie';
import devuelveEscuela from './devuelveEscuela';



var muestraEdita=false;
const cookies=new Cookies();

function Main() {
  var Usuario=obtieneDatos()
const navitate = useNavigate()
const logueaUsuario=async()=>{
  const usuarioObtenido=await axios.post(process.env.REACT_APP_BASEURL+"usuarios/"+Usuario.identificacion)

  seteaCokie(usuarioObtenido.data)
  Usuario=obtieneDatos()

}
useEffect(() => {
  logueaUsuario()
 
}, []);

if(!Usuario){
  alert("Acceso no Autorizado")
   window.location.href='./';
   
}else{
  if(!Usuario.id){
    alert("Acceso no Autorizado")
    window.location.href='./';
  }
}

const Dependencias=async()=>{
  return await devuelveDependenciaConstultada()
}
const [DependenciasExistentes, setDependenciasExistentes]=useState(null)

useEffect(() => {
  Dependencias().then(f=>setDependenciasExistentes(f))
}, []);
const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({});



  const volver=()=>
  {
    
    navitate('/')
  }
  
  const sacarExpediente=()=>{
    navitate('/sacaExpediente')
  };
  const recibirExpediente=()=>{
    navitate('/recibeExpediente')
  };
  const consultaExpediente=()=>{
    navitate('/consultaExpediente')
  };
 
 
const editaUsuarios=()=>{
  navitate('/usuarios')
};
const inscripcion2024=()=>{
  navitate('/ingreso2024')
};


const cadetes=()=>{
  navitate('/cadetes')
}
const procesos=()=>{
  navitate('/procesos')
}
const menuCertificados=()=>{
  navitate('/Certificados')
}
const menuDocentes=()=>{
  navitate('/Docentes')
}
const calificaciones=()=>{
  navitate('/Calificaciones')
}
const resoluciones=()=>{
  navitate('/Resoluciones')
}
  return (
    <div className='body_msenu'>
    <div >
    <div style={{textAlign:"right"}}>
    <h4 className="">Bienvenido <button className='btn btn-secondary' onClick={editaUsuarios}>{Usuario.nombre + " "+Usuario.apellido}     </button></h4>
    </div>
    <div className='menu_edit' >
    <div style={{textAlign:"right"}}>
    
   

          </div>
        
          </div>
          

        <section className="">
      
        <div className='escudo_loguin'style={{marginBottom:"10px"}}>
        
        </div>
        <div  style={{width: "100%",padding:"10px",textAlign:"center"}} >

          {Usuario.destino=="22"||Usuario.destino=="21"||Usuario.destino=="9"||Usuario.destino=="7"||Usuario.destino=="1"||Usuario.destino=="25"||Usuario.destino=="2"||Usuario.destino=="16"?<> <input className='button_menu' type="button" value="Cadetes" style={{width:"auto",marginBottom:"15px",height:"150px",padding:"10px",marginInline:"10px",fontSize:"30px"}}  onClick={cadetes}/>
          </>:""}
          {Usuario.destino=="40"||Usuario.destino=="9"||Usuario.destino=="7"||Usuario.destino=="1"||Usuario.destino=="25"||Usuario.destino=="2"?<> <input className='button_menu' type="button" value="Procesos" style={{width:"auto",marginBottom:"15px",height:"150px",padding:"10px",marginInline:"10px",fontSize:"30px"}}  onClick={procesos}/>
          </>:""}


          {Usuario.destino>="10"&&Usuario.destino<"29"||Usuario.destino=="7"||Usuario.destino=="45"||Usuario.destino=="1"?<> <input className='button_menu'style={{width:"auto",height:"150px",marginInline:"10px",marginBottom:"15px",padding:"10px",fontSize:"30px"}}type="button" value="Docentes"  onClick={menuDocentes}/>
          </>:""}



          {Usuario.destino=="10"||Usuario.destino=="7"||Usuario.destino=="1"||Usuario.destino=="39"?<> <input className='button_menu'style={{width:"auto",height:"150px",marginInline:"10px",marginBottom:"15px",padding:"10px",fontSize:"30px"}}type="button" value="Certificados"  onClick={menuCertificados}/>
          </>:""}
          
       
          
          
       {
        Usuario.destino>"0"? <input type="button" value="Expedientes"className='button_menu' style={{width:"auto",height:"150px",marginBottom:"15px",marginInline:"10px", padding:"10px",fontSize:"30px"}} onClick={consultaExpediente}/>:""
       }
       
     
       {
        Usuario.destino=="7"? <input type="button" value="Calificaciones"className='button_menu' style={{width:"auto",height:"150px",marginBottom:"15px",marginInline:"10px", padding:"10px",fontSize:"30px"}} onClick={calificaciones}/>:""
       }
        
         
          {
        devuelveEscuela()=="7"||devuelveEscuela()=="25"||devuelveEscuela()=="26"||devuelveEscuela()<="27"||devuelveEscuela()=="28"||devuelveEscuela()=="1"? <input type="button" value="Resoluciones"className='button_menu' style={{width:"auto",height:"150px",marginBottom:"15px",marginInline:"10px", padding:"10px",fontSize:"30px"}} onClick={resoluciones}/>:""
       }
        
       
        </div>
        
        <div>
         
      
        </div>


        
        <div className="contenedor_boton_edit"style={{width:"100%",textAlign:"center"}}>
          <input  type="button" value="Salir" className="form__submit" onClick={volver} style={{width:"100px",marginTop:"5px"}}/>
          
        </div>
        </section>
        <div style={{width: "100%",textAlign:"center"}}> 
       
        <FooterCorp />
        </div>

     
      
    </div></div>
  );
}
export default Main;
